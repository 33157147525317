.pb--block--tab-panel {
	
	.tabs {

		border: none;
		text-align: center;
	    display: flex;
	    flex-wrap: wrap;

		.tabs-title {

		    // float: none;
		    display: inline-block;
		    text-align: center;
		    border: 1px solid $light-gray;
	        // width: 50%;
            flex-grow: 1;

		    &>a {

		    	font-size: 18px;
		    	color: $black;
		    	font-weight: 500;

		    	&:hover {
		    		background-color: $light-gray;
		    	}

		    }

		    &:first-child {
		    	border-radius: $global-radius 0 0 $global-radius;
		    }

		    &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5) {
		    	border-left: none;
		    }

		    &:last-child {
		    	border-left: none;
		    	border-radius: 0 $global-radius $global-radius 0;
		    }

		    &.is-active {
		    	border: 1px solid $light-gray;
		    }

		}

		.tabs-title>a:focus, .tabs-title>a[aria-selected='true'] {
    		background: $light-gray;
		}

	}

	.tabs-content {

		border: none;

		.tabs-panel {
    		padding: 0;
		}

	}
	
}
