.pb--2-col {
	
	p, li {
		line-height: 32px;
	}

	ul {

	  list-style-type: none;
	  
	  li {

	  	position: relative;
  	    padding-bottom: 16px;
	    
	  	&:before {
	  		content: "\f101";
			font-family: 'Font Awesome 5 Pro';
			color: $primary-color;
			margin-right: 8px;
		    font-size: 14px;
		    position: absolute;
	        left: -20px;
	  	}

	  }

	}

}