.pb--block--heading-links {

	.menu {

    	padding-top: 1rem;

    	a {

    		color: $secondary-color;
		    text-decoration: underline;

    		&:hover {
    			color: $primary-color;
    		}

    	}

	}

}