
/* - - - - - - - - - - - - PB IMAGE TEXT HALF CONTAINED - - - - - - - - - - - - */

.pb--half-contained {

	.pb--half--outer {
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    height: 100%;
   }

  .pb--half--outer--content {

    .pb--half--inner--content {

      h3 {
        text-transform: uppercase;
      }

    }

  }

  .pb--half--outer--img {

    .pb--half--inner--img {

      width: 100%;

      img {
        box-shadow: 0 10px 40px rgba($black,0.15);
        border-radius: $global-radius;
        width: 100%;
      }

    }

  }

}

@media only screen and (max-width: 1023px) {

  .pb--half-contained--text-img .cell:first-of-type {
      order: 1;
  }

}
