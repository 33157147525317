header { position:absolute; top:0; z-index:9; width:100%; padding:2rem 0;
  @include breakpoint(640 down) { padding:1rem 0; }
  .header-main-logo {
    svg { max-width:185px; width:100%; transition:0.3s;
      @include breakpoint(large down) { max-width:165px; }
      @include breakpoint(xmedium down) { max-width:145px; }
      @include breakpoint(small down) { max-width:135px; }
    }
  }
  &.primary {
    .header-main-logo {
      svg {
        path { fill:$primary-color; }
      }
    }

    .main-navigation__list {
      li {
        a { color:$primary-color; }
      }
    }

    .mobile-navigation {
      .menu-bar-icon__bar {
        background-color:$primary-color;
      }
      .menu-bar-icon {
        &:before {
          background-color:$primary-color;
        }
        &:after {
          background-color:$primary-color;
        }
      }
    }
  }
  .main-navigation {
    &__list {
      display:flex; align-items:center; margin:0; list-style-type:none; justify-content:flex-end;

      li { margin-right:1.25rem; padding:0.45rem 0;
        @include breakpoint(1200 up) { margin-right:1.85rem; }
        .button { font-size:14px; line-height:1.1; padding:0.8rem 1.3rem; font-weight:$global-weight-medium;
            @include breakpoint(1200 up) {  font-size:14px; }
        }
        a { color:$white; font-weight:$global-weight-normal; font-size:14px; transition:0.3s; display:flex;
          @include breakpoint(1200 up) { font-size:15px; }
          &:hover { color:$secondary-color; }
        }
        &:last-child { margin-right:0; display:flex; align-items:center;
          a { font-weight:$global-weight-medium; font-size:18px; }
          span { margin-right:5px; font-weight:$global-weight-medium; font-size:18px; }
        }
        &:not(:last-child) {
          @include breakpoint(1200 down) { display:none; }
        }
      }
    }
  }

  &.mobile-navigation { z-index:9999; position:fixed;
    .header-main-logo {
      svg {
        path { fill:$primary-color; }
      }
    }
    .main-navigation__list {
        li {
          &:not(:last-child) { display:none; }
        }
    }
  }

  /* Mobile Navigation */
  .mobile-navigation { width:100%; display:flex; justify-content:flex-end;
    @include breakpoint(640 down) { padding-bottom:8px; }
    .menu-bar-icon { width:28px; height:20px; position:relative; z-index:10; cursor:pointer;
      &::before {
        content: ""; width:28px; height:2px; background-color:$white; position:absolute; top:2px; left:0; border-radius:5px; transition:0.5s ease-out;
      }
      &__bar {
        width:28px; height:2px; background-color:$white; position:absolute; top:10px; left:0; border-radius:5px; transition:0.3s ease-out;
      }
      &::after {
        content: ""; width:28px; height:2px; background-color:$white; position: absolute; bottom: 0; left: 0; border-radius:5px; transition:0.5s ease-out;
      }

      &.active {
        &::before { transform:rotate(45deg); top:10px; background-color:$primary-color; }
        .menu-bar-icon__bar { opacity:0; }
        &::after { transform:rotate(135deg); top:10px; background-color:$primary-color; }
      }
    }
  }
}

/* Mobile Navigation Content */
.mobile-navigation__content { position:fixed; top:0; left:0; right:0; bottom:0; z-index:999; background:rgba($white,0.95); height:0; overflow:hidden; transition:0.5s ease-out;
  .mobile-navigation__content-inner { flex-wrap:wrap; position:relative; top:6rem; height:calc(100% - 6rem); width:100%; display:flex; justify-content:center; max-width:1280px; margin:0 auto; padding:0 0.9375rem;
    @include breakpoint(640 down) { height:calc(100% - 4.5rem); overflow-y: scroll; top:4.5rem; }
    .mobile-navigation-list { margin:0; list-style-type:none; padding-top:2rem; width:50%; columns:2;
      @include breakpoint(640 down) { width:100%; padding-top:0.5rem;}
      @include breakpoint(480 down) { columns:1; }
      li { width:95%; margin:0 auto;
        @include breakpoint(640 down) { width:100%; }
        a { color:rgba($primary-color,0.8); font-size:24px; font-weight:$global-weight-normal; transition:0.3s; margin:0.8rem 0; display:inline-block; line-height:1;
          @include breakpoint(1024 down) { font-size:20px; }
          &:hover { color:$primary-color; text-decoration:underline; font-weight:$global-weight-medium; }
          .search-mobile__button-trigger {
            .search-icon { font-size:16px; margin-left:8px; vertical-align:unset;
              &:hover { color:$primary-color; }
            }
          }
        }
      }

      &--small { width:38%; margin-left:12%;
        @include breakpoint(1024 down) { margin-left:5%; }
        @include breakpoint(640 down) { width:100%; margin-left:0; padding-top:1rem; }
        @include breakpoint(480 down) { columns:2; }
        li {
          a { font-size:16px; color:#333333; }
        }
      }
    }
    .mobile-navigation-cta { display:flex;
      @include breakpoint(640 down) { flex-wrap:wrap; justify-content:center; text-align:center; margin-top:1.5rem; }
      p { font-size:16px;
        @include breakpoint(640 down) { font-size:14px; }
        .button { margin-bottom:0; margin-left:0.5rem;
          @include breakpoint(640 down) { margin-top:0.75rem; }
        }
      }
    }
  }
  &.active {
    height:30%; min-height:450px;
    @include breakpoint(640 down) { height:100%; }
  }
}
