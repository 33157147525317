.pb--quote {

	padding: 0 !important;

	img {
		margin: 0 auto;
		display: block;
	}

	.offset-wrapper {
		padding: 3%;
		position: absolute;
		width: 40%;
		bottom: -3rem;
		right: 0;
		background: $secondary-color;
		color: $white;
		border-radius: $global-radius;

		p {
			font-size: 18px;
		}

		a {

			color: $white;
		    font-size: 14px;
		    font-weight: 700;

			&:before {
			    content: '\f324';
			    font-family: 'Font Awesome 5 Pro';
			    font-size: 10px;
			    margin: 0 6px 0 0;
			    color: $black;
			}

			&:hover {
				color: $black;
			}

		}

	}

}

@media only screen and (max-width: 639px) {

	.pb--quote .offset-wrapper {

    	width: 100% !important;
	    bottom: -7rem !important;
	    // width: 50% !important;
	    // bottom: 0 !important;
        border-radius: 0 !important;

        p {
			font-size: 14px !important;
        }

    }

}

