.pb--survey {

	.grid-container {

		// border: 1px solid #ccc;
		// border-radius: $global-radius;

		.cell {



			&:last-of-type {
				text-align: right;
			}

			.pb--survey--inner {

				border: 1px solid #ccc;
				border-radius: $global-radius;

				// padding-top: 24px;
				// padding-bottom: 24px;
				padding: 24px 20px;

				.plain {
					height: 32px;
					padding-top: 0;
				    padding-bottom: 0;
				    line-height: 32px;
				}

			}

			p {
				margin-bottom: 0;
			}

			a {
				color: $secondary-color;
			}

		}

	}

}