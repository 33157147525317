.pb--block--gallery {

	.masonry {
		column-count: 1;
	    // column-gap: 1em;
	}

	/* The Masonry Brick */
	.item {

		background: #fff;
		// padding: 1em;
		padding: 0;
		margin: 0 0 1em;

		img {
		  	border-radius: $global-radius;
		}

	}

}

/* Masonry on large screens */
@media only screen and (min-width: 1024px) {

	.pb--block--gallery .masonry {
    	column-count: 3;
	}
	
}

/* Masonry on medium-sized screens */
@media only screen and (max-width: 1023px) and (min-width: 768px) {

	.pb--block--gallery .masonry {
		column-count: 2;
	}

}

/* Masonry on small screens */
@media only screen and (max-width: 767px) and (min-width: 540px) {

	.pb--block--gallery .masonry {
		column-count: 2;
	}

}
