/* Text Image Page Builder  */
.pb-text-image-panel { 
  &__image-container { position:relative; margin-right:0;
    @include breakpoint(1200 up) { margin-right:2rem; }
    &:before {
      @include breakpoint(1200 up) { z-index:-1; content:""; position:absolute; background:#BEEEE4; display:inline-block; width:55%; height:100%; }
    }
    img {
      @include breakpoint(1200 up) { left:30px; top:30px; position:relative; }
    }
    .testimonial-play-button { transition:0.5s; background:rgba($white,0.9); margin-left:0; display:inline-block; border-radius:100%; height:75px; width:75px; position:absolute; top:50%; left:50%; transform:translate(-50%, -50%); border:1px solid $white;
      @include breakpoint(1200 up) { top:58%; margin-left:1.25rem; }
      @include breakpoint(480 down) { margin-left:0; margin-top:1rem; height:65px; width:65px; }
      i { font-size:14px; color:$primary-color; position:absolute; top:50%; left:50%; transform:translate(-50%,-50%); }
      &:hover { border:1px solid $white; background:$white;
        i { color:$secondary-color; }
      }
    }
  }

  &__text-container {
    @include breakpoint(1200 up) { max-width:520px; }
    h3 { font-size:32px; margin-bottom:1.05rem; color:#333333; font-weight:$global-weight-light;
      @include breakpoint(640 down) { font-size:23px; font-weight:$global-weight-medium; }
      @include breakpoint(480 down) { font-size:20px; }
    }
    p { font-size:16px; color:#333333; font-weight:$global-weight-normal;
        @include breakpoint(640 down) { font-size:15px; }
        @include breakpoint(480 down) { font-size:14px; }
    }
  }

  &__button-container { margin-top:0.75rem;
    .button { display:inline-block; }
  }

  &.image-right {
    .pb-text-image-panel__image-container {
      @include breakpoint(1200 down) { margin-bottom:1.25rem; }
    }
  }

  &.image-left {
    .pb-text-image-panel__text-container { margin-left:auto; }
    .pb-text-image-panel__image-container {
      @include breakpoint(1200 down) { margin-bottom:1.25rem; }
    }
  }

}

/* Page Buidler CTA BG */
.pb-cta-bg {
  &__overlay { position:absolute; top:0; left:0; right:0; bottom:0; background:rgba(17,17,17,0.4); }
  &__image { position:relative; background-repeat:no-repeat; background-size:cover; background-position:center; }
  &__text { color:$white; padding:8.5rem 0; position:relative; z-index:10;
    @include breakpoint(640 down) { padding:4rem; }
    @include breakpoint(480 down) { padding-top:10rem; padding-left:1.25rem; padding-right:1.25rem; padding-bottom:2rem; }
    h3 {
      @include breakpoint(640 down) { margin-bottom:1.5rem; }
    }
    p {
      @include breakpoint(640 down) { display:none; }
    }
  }
}

/* Page Builder Download */
.pb-downloads {
  .download-item { display:flex; align-items:center; padding:1.5rem; border:1px solid #F4F4F4; background:$white; margin-bottom:15px;
    @include breakpoint(900 down) { flex-wrap:wrap; }
    @include breakpoint(480 down) { padding:1.25rem; }
    &__icon { width:6%; padding-right:1rem; text-align:center;
      @include breakpoint(900 down) { width:60px; }
      i { font-size:32px; color:$secondary-color;
        @include breakpoint(640 down) { font-size:26px; }
      }
    }
    &__text { width:79%;
      @include breakpoint(900 down) { width:100%; margin-top:0.5rem; }
      h4 { font-size:17px; font-weight:$global-weight-medium; margin-bottom:2px; line-height:1.3;
        @include breakpoint(640 down) { font-size:16px; }
        @include breakpoint(480 down) { font-size:15px; font-weight:$global-weight-normal; }
        span {  font-size:14px; font-weight:$global-weight-light; color:#959595; padding-left:1rem;
          @include breakpoint(640 down) { font-size:13px; }
          @include breakpoint(480 down) { display:block; padding-left:0; margin-bottom:8px; }
        }
      }
      p { margin-bottom:0; font-size:17px; line-height:1.2; font-weight:$global-weight-normal;
          @include breakpoint(640 down) { font-size:15px; }
          @include breakpoint(480 down) { font-size:14px; }
      }
    }
    &__link { width:15%; text-align:right;
      @include breakpoint(900 down) { width:100%; text-align:left; margin-top:1rem; }
      i { font-size:20px; margin-right:4px; }
      a { font-size:16px; font-weight:$global-weight-medium; text-decoration:underline;
        @include breakpoint(640 down) { font-size:15px;}
        &:hover { color:$secondary-color; }
      }
    }
  }
}

/* Page Builder Grouped Content (Tabs)*/
.tabs {
  &.custom-tabs {
    border: none; margin-left: 0 !important; display: flex;
    @include breakpoint(large down) { overflow-x:scroll; }
    &::-webkit-scrollbar { width:4px; height:3px;
      @include breakpoint(large up) { height:0; }
    }
    &::-webkit-scrollbar-track { background: #f1f1f1; }
    &::-webkit-scrollbar-thumb { background:rgba(0,0,0,0.2); border-radius:5px; }
    &::-webkit-scrollbar-thumb:hover { background: #555; }

    .tabs-title {
      border:1px solid #f7f7f7; border-bottom:0; background-color:#F7F7F7;
      margin-right: 5px; text-align: center; min-width:125px; border-top-left-radius:4px; border-top-right-radius:4px;

      &.is-active, &>a:focus, &>a[aria-selected='true'] {
        background: $white;
        color: $primary-color;
      }

      &>a:hover {
        background: rgba(51,51,51,.1) !important;
      }

      &:last-child { margin-right:0; }

      a {
        color:$primary-color; font-size:16px; text-decoration:none; font-weight:$global-weight-bold;
      }
    }
  }
}

.tabs-content {
  &.custom-tabs-content {
    border-color:#F7F7F7; padding:0; padding-bottom:0; border-top:1px solid #F7F7F7;
    .tabs-panel { padding-top:2rem; padding-bottom:1rem; padding-left:2rem; padding-right:2rem;
      @include breakpoint(640 down) { padding-left:1rem; padding-right:1rem; }
    }
  }
}

/* Page Builder Grouped Content (Accordion) */
.accordion { background:none;
  &.custom-accordion {
    .accordion-item { margin-bottom:8px;
      &.is-active {
        .accordion-title { border-bottom:0; background:$white;
          &:before { background:#F4F4F4; }
        }
        .accordion-content { border-top:0; }
      }
      .accordion-title { background:$secondary-color; color:#000; border-radius:0; font-weight:bold; padding:1.1rem 1.75rem; font-size:16px; border-color:#000; border-bottom:1px solid;
      }
    }
  }
  .accordion-content {
    &.custom-accordion-content {
      border-color:#000; border-bottom:1px solid #000; border-radius:0;
    }
  }
}

/* Page Builder Video Block */
.pb-image-video-block {
  .image-video-block {
    &__image-container { position:relative;
      img { width:100%; }
      .play-button { transition:0.5s; background:rgba($white,0.9); margin-left:1.5rem; display:inline-block; border-radius:100%; height:65px; width:65px; position:absolute; top:50%; left:50%; transform:translate(-50%, -50%); border:1px solid $white;
        @include breakpoint(480 down) { margin-left:0; height:45px; width:45px; }
        i { font-size:14px; color:$primary-color; position:absolute; top:50%; left:50%; transform:translate(-50%,-50%); }
        &:hover { border:1px solid $white; background:$white;
          i { color:$secondary-color; }
        }
      }
    }
  }
  .slick-arrow { position:absolute; bottom:-75px;
    @include breakpoint(800 down) { bottom:-100px; }
    &.slick-prev { left:50%; transform:translateX(-50%);  margin:0 -25px; }
    &.slick-next { left:50%; transform:translateX(-50%); margin:0 20px; }
  }
}
