section.page-builder {

  header {
    margin-bottom: 3rem;
    .cell {
    	h2 {
    		margin: 0 0 1rem 0;
    	}
	    p.section-intro {
	      font-size: 1.375rem;
	      margin: 0;
	    }
	    *:last-child {
	      margin: 0;
	    }    	
	  }
  }

  section {
  	// padding: 0;
  }

  footer {
    margin-top: 3rem;
    .cell {
	    *:last-child {
	      margin: 0;
	    }    	
	  }
  }

}




@import 'intro';
@import '2-col';
@import 'accordion';
@import 'cards';
@import 'quote';
@import 'carousel-graphic';
@import 'downloads';
@import 'form';
@import 'gallery';
@import 'half-contained';
@import 'half-bg';
@import 'heading-links';
@import 'highlight';
@import 'icons-panel';
@import 'links-horizontal';
@import 'pricing';
@import 'survey';
@import 'tab-panel';
@import 'testimonial';
@import 'video';