.page-builder.pricing {

  .card__pricing {
    background: $white;
    padding: 4rem 0 0 0;
    border: $global-border;
    &:hover {
      // transform: scale(1.025);
      -webkit-box-shadow: 0px 0px 16px 8px rgba(238,238,238,0.3333);
      -moz-box-shadow: 0px 0px 16px 8px rgba(238,238,238,0.3333);
      box-shadow: 0px 0px 16px 8px rgba(238,238,238,0.3333);
    }
    span {
      display: block;
    }
    .pricing__name {
      font-weight:  500;
      margin: 0 0 1rem 0;
    }
    .pricing__price {
      font-size:  3.5rem;
      color: $primary-color;
      margin: 0;
    }
    .pricing__active {
      font-size:  1.25rem;
      font-weight:  500;
      color: $secondary-color;
      margin: 0 0 2rem 0;
    }
    ul {
      padding: 0 4rem;
      list-style: none;
      margin: 0 0 4rem 0;
      li {
        border-bottom: $global-border;
        color: $black;
        padding: .625rem 1rem;
        font-size: .875rem;
        i {
          margin: 0 .25rem 0 0;
          color: $primary-color;
        }
        &:last-of-type {
          border: none;
        }
      }
    }
    .button {
      background: $medium-gray;
      color: $black;
      font-weight: 500;
      font-size: 1.125rem;
      padding: 1.25rem 0;
    }
    &.best {
      border-color: $secondary-color;
      position: relative;
      .pricing__popular {
        position: absolute;
        top: -1.25rem;
        background: $secondary-color;
        color: $white;
        padding: .5rem 1rem;
        font-size: .875rem;
      }
      .button {
        background: $secondary-color;
        color: $white;
      }
    }
  }  
  
}