.carousel-graphic-image {
  float: left;
  width: 60%;
  position: absolute;
  top: 0;
  left: 0;
}

.carousel-graphic-content {
  float: right;
  width: 50%;
  background: $secondary-color;
  padding: 8rem 2rem;
  color: $white;
  margin: 8rem 0 0 0;
  img {
    margin-bottom: 2rem;
  }
  h4 {
    color: $white;
  }
  p {
    margin: 0;
  }
}

.controls {
  float: right;
  position: absolute;
  bottom: 2rem;
  right: 3rem;
  z-index: 1000;
  .slick-next, .slick-prev {
    display: block;
    float: left;
    width: 2rem;
    background: $primary-color;
    color: #FFF;
    margin-left: .25rem;
    cursor: pointer;
    font: 1rem/2rem FontAwesome;
    text-align: center;
    transition: all 0.5s;
  }
  .slick-next:before {
    content: '\f105';
  }
  .slick-prev:before {
    content: '\f104';
  }
} 




@media screen and (max-width: 64em) {
  .carousel-graphic-image {
    width: 90%;
  }
  .carousel-graphic-content {
    width: 75%;
    padding: 4rem 2rem;
    margin: 16rem 0 0 0;
  }
  .controls {
    float: right;
    position: absolute;
    bottom: 1rem;
    right: 2rem;
    z-index: 1000;
  }
}




@media screen and (max-width: 40em) {
  .carousel-graphic-image {
    width: 100%;
  }
  .carousel-graphic-content {
    width: 100%;
    padding: 3rem 1.5rem;
    margin: 16rem 0 0 0;
  }
  .controls {
    float: right;
    position: absolute;
    bottom: 1rem;
    right: 2rem;
    z-index: 1000;
  }
}

