$my-palette: (
  primary: $primary-color,
  secondary: $secondary-color,
  dark-blue: #093341,
  whitesmoke: #F7F7F7,
  dark-black: #333333,
  success: $success-color,
  warning: $warning-color,
  alert: $alert-color,
  light-gray: $light-gray,
  medium-gray: $medium-gray,
  dark-gray: $dark-gray,
  black: $black,
  white: $white
);

// text colours
@each $key, $value in $my-palette {
  .color--#{"" + $key} { color: $value!important; }
}

// background colours
@each $key, $value in $my-palette {
  .bg--#{"" + $key} { background-color: $value !important; }
}

// background colours
@each $key, $value in $my-palette {
  .button--#{"" + $key} { background-color: $value; }
}

// @function my-palette($key: 'primary') {
//   @return map-get($my-palette, $key);
// }
