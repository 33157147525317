body {
  &.hidden {
    @include breakpoint(640 down) { overflow:hidden;}
  }
}

.grid-container {
  &--lg { max-width:1780px; }
  &--no-padding { padding-left:0; padding-right:0; }
  > .cell { 
    @include breakpoint(480 down) { padding-left:36px; padding-right:36px; }
    @include breakpoint(370 down) { padding-left:1rem; padding-right:1rem; }
  }
}

.section-padding { padding:6.25rem 0;
  @include breakpoint(640 down) { padding:3.125rem 0; }
  @include breakpoint(480 down) { padding:2rem 0; }

  &--sm { padding:3.25rem 0;
    @include breakpoint(640 down) { padding:2.75rem 0; }
    @include breakpoint(480 down) { padding:2rem 0; }
  }
}

.title-section { margin-bottom:1.25rem; letter-spacing:-0.5px; }
.paragraph-section { margin-bottom:2rem; }


p { font-size:16px;
  @include breakpoint(640 down) { font-size:14px; }
}

.link { color:$primary-color; transition:0.5s; text-decoration:underline; font-weight:$global-weight-medium;
  &:hover {
    color:$secondary-color;
  }
}
.view-more-link { color:$primary-color; transition:0.5s;
  &:hover {
    color:$secondary-color;
  }
  span { font-weight:$global-weight-medium; font-size:15px; text-decoration:underline; }
    i { font-size:12px; margin-left:5px; }
}

.button { position:relative; overflow:hidden; transition:color 1.2s; z-index:10; line-height:1.2;
  @include breakpoint(640 down) { font-size:14px; }
  &:before {
    content: ''; position:absolute; top:0; left:0; width:125%; height:100%; background:$primary-color; transform-origin:0 0;
    transform:translateX(-100%) skewX(-45deg); transition:transform 0.6s; z-index:-1;
  }
  &:hover { color:$white; box-shadow:0 0 18px rgba($secondary-color,0.65);
    &:before { transform:translateX(0) skewX(-45deg); }
  }

  &.secondary { color:$primary-color;
    &:hover { color:$white; }
    &:before { background:$primary-color; }

    &.white-text { color:$white; }
  }

  &.primary {
    &:hover { color:$white; }
    &:before { background:#33798F; }
  }

  &.white { color:$primary-color; background:$white;
    &:hover { color:$white; }
    &:before { background-color:$primary-color; }
  }

  &--white-outline { color:$white; background:none; outline:1px solid #fff;
    &:before { background:$secondary-color; }
    &:hover { outline-color:transparent; color:$primary-color!important; }
  }

  &--primary-outline { color:$primary-color; background:none; outline:1px solid $primary-color;
    &:before { background:$secondary-color; }
    &:hover { outline-color:transparent; color:$primary-color!important; }
  }
}


/* Home Banner */
.home-banner { position:relative;
  &__inner {
    .home-banner-item {
      &__bg-image { background-repeat:no-repeat; background-position:center; background-size: cover; height:100%;
        &.custom-padding { padding-top:15.75rem; padding-bottom:15.75rem;
            @include breakpoint(medium down) { padding-top:12.75rem; padding-bottom:12.75rem; }
            @include breakpoint(small down) { padding-top:8rem; padding-bottom:10.75rem; }
        }
      }
      &__text-container { z-index:99; position:relative;
        h1 { color:$white; font-weight:$global-weight-light; font-size:60px; line-height:1.1; margin-bottom:1rem;
          @include breakpoint(768 down) { font-size:48px; }
          @include breakpoint(640 down) { font-size:38px; }
          @include breakpoint(480 down) { font-size:30px; }
        }
        p { color:$white; font-weight:$global-weight-light; font-size:18px; margin-bottom:1.15rem;
          @include breakpoint(640 down) { font-size:16px; }
          @include breakpoint(480 down) { font-size:13px; }
        }
      }

      &__button-container { display:flex; align-items:center;
        @include breakpoint(480 down) { flex-wrap:wrap; }
        .button { margin-bottom:0;
          @include breakpoint(480 down) { width:100%; }
        }
        .play-button { transition:0.5s; margin-left:1.5rem; display:inline-block; border-radius:100%; height:45px; width:45px; position:relative; border:1px solid $white;
          @include breakpoint(480 down) { margin-left:0; margin-top:1rem; }
          i { font-size:8px; color:$white; position:absolute; top:50%; left:50%; transform:translate(-50%,-50%); }
          &:hover { border:1px solid $white; background:$white;
            i { color:$primary-color; }
          }
        }
        span { margin-left:10px; color:$white; font-size:15px; font-weight:$global-weight-medium;
          @include breakpoint(480 down) { margin-top:15px;}
        }
      }
    }

    .slick-slide { padding:0; }

    .slick-dots { position:absolute; bottom:40px;
      @include breakpoint(small down) { width:100%; left: 50%; transform: translateX(-50%); }
      li { cursor:pointer; width:10px; height:10px; margin-bottom:5px; border:2px solid transparent; background:rgba($white,0.6); margin-left:4px; margin-right:4px;
        &.slick-active {
          background:$secondary-color; border-color:$secondary-color;
        }
      }
    }
  }
}

/* Highlight Text Panel */
.highlight-text-panel {
  h2 { color:$primary-color;
    @include breakpoint(640 down) { margin-bottom:1.25rem; }
 }
  p { font-size:15px; color:$primary-color; }
}

/* Product Showcase */
.product-showcase-items { display:flex; flex-wrap:wrap;
  .product-showcase-block { width:50%; position:relative; display:inline-block; overflow:hidden;
    &:hover {
        .product-showcase-block__overlay { visibility:visible; opacity:1; z-index:15; }
        .product-showcase-block__video { visibility:visible; opacity:1; z-index:10; }
        .product-showcase-details { transform:translateY(0); z-index:20; width:100%;  }
    }
    &__overlay{ transition:visibility 0s, opacity 0.5s linear; position:absolute; top:0; left:0; right:0; bottom:0; background:rgba($primary-color,0.5); width:100%; height:100%; z-index:-10; opacity:0; visibility:hidden;
      @include breakpoint(480 down) { display:none; }
    }

    &__image { background-repeat:no-repeat; background-position:center; background-size:cover; height:380px;
      @include breakpoint(1280 up) { height:450px; }
      @include breakpoint(640 down) { height:345px; }
      @include breakpoint(480 down) { height:250px; }
      @include breakpoint(380 down) { height:180px; }
    }
    &__video { position:relative; transition:visibility 0s, opacity 0.5s linear; position:absolute; top:0; left:0; bottom:0; right:0; height:100%; z-index:-20; visibility:hidden; opacity:0;
      @include breakpoint(768 down) { display:none; }
    }

    .product-icon-title { position:absolute; top:30px; left:30px; display:flex; align-items:center;
      @include breakpoint(480 down) { top:15px; left:15px; }
      &__icon { width:35px;
        @include breakpoint(640 down) { display:none; }
        &.ev-charging { width:20px;
            @include breakpoint(640 down) { display:none; }
        }
      }
      &__text { color:$white; font-size:20px; font-weight:$global-weight-medium; margin-left:10px; display:inline-block; line-height:1;
          @include breakpoint(768 down) { font-size:1rem; }
          @include breakpoint(640 down) { margin-left:0; }
          @include breakpoint(480 down) { font-size:0.85rem; }
          @include breakpoint(380 down) { height:0.75rem; }
      }
    }
    .product-showcase-details { transition:transform 0.5s; position:absolute; bottom:25px; z-index:-99; transform:translateY(125%);
      @include breakpoint(480 down) { display:none; }
      ul { display:flex; margin:0; list-style:none; justify-content:center;
        @include breakpoint(1080 down) { flex-wrap:wrap; }
        li { color:$white; text-align:center; padding:0 1rem; position:relative; 
          @include breakpoint(1200 up) { width:33.33%; }
          @include breakpoint(1080 down) { max-width:200px; }
          &:first-child {
             &:before { display:none; }
          }
          &:before {
            @include breakpoint(1200 up) { content:""; display:inline-block; position:absolute; left:0; top:50%; height:50px; width:1px; transform:translateY(-50%); z-index:99; background:rgba($white,0.2); }
          }
          span { color:$white; font-size:1.5rem; font-weight:$global-weight-medium;
            @include breakpoint(1280 up) { font-size:2rem; }
            @include breakpoint(768 down) { font-size:1.25rem; }
            .small-text { font-size:0.75rem;
              @include breakpoint(1280 up) { font-size:0.85rem; }
              @include breakpoint(768 down) { font-size:0.65rem; }
            }
          }
          p { color:$white; font-size:0.8rem; line-height:1.2; font-weight:$global-weight-medium; max-width: 235px; margin-left:auto; margin-right:auto;
            @include breakpoint(1280 up) { font-size:0.9rem; }
            @include breakpoint(768 down) { font-size:0.7rem; }
          }
        }
      }
    }
  }
}

/* Brands Carousel */
.brands {
  h4 { text-align:center; margin-bottom:2rem; font-size:1rem; font-weight:$global-weight-medium; }
  .brands-carousel {
     @include breakpoint(768 up) { display:flex; justify-content:center; align-items:center; }
    .item { padding:0 1.5rem;
      @include breakpoint(768 down) { padding:0 0.85rem; }
      img { filter: grayscale(1); }
    }
    .slick-slide {
      img { margin:0 auto; }
    }
  }
}

.testimonial-carousel {
  .slick-arrow { position:absolute; bottom:15%;
    @include breakpoint(1500 up) { bottom:22%; }
    @include breakpoint(1200 down) { bottom:-7%; }
    &.slick-prev { left:56%; transform:translateX(-50%);  margin:0 -25px;
      @include breakpoint(1601 up) { left:60.5%; }
      @include breakpoint(1600 down) { left:62%; }
      @include breakpoint(1024 down) { left:56%; }
      @include breakpoint(768 down) { left:50%; }
    }
    &.slick-next { left:56%; transform:translateX(-50%); margin:0 20px;
      @include breakpoint(1601 up) { left:60.5%; }
      @include breakpoint(1600 down) { left:62%; }
      @include breakpoint(1024 down) { left:56%; }
      @include breakpoint(768 down) { left:50%; }
    }
  }
}

/* Text Image Panel */
.text-image-panel {
  &__image-container { position:relative;
    &:before {
      @include breakpoint(1024 up) { z-index:-1; content:""; position:absolute; background:#BEEEE4; display:inline-block; width:55%; height:95%; }
    }
    img {
      @include breakpoint(1024 up) { left:30px; top:30px; position:relative; width:100%; }
    }
  }

  &__text-container {
    @include breakpoint(768 down) { text-align:center; }
    .rating-stars { display:flex; margin-bottom:1.5rem;
      @include breakpoint(768 down) { justify-content:center; }
      i { color:#F4DF24; font-size:14px; margin-right:4px; }
    }
    h4 { font-size:32px; margin-bottom:1.4rem; color:$primary-color; font-weight:$global-weight-light;
      @include breakpoint(640 down) { font-size:23px; font-weight:$global-weight-medium; }
      @include breakpoint(480 down) { font-size:20px; }
    }
    p { font-size:18px; font-weight:$global-weight-light;
        @include breakpoint(640 down) { font-size:16px; }
        @include breakpoint(480 down) { font-size:15px; }
    }
    .testimonial-name { display:flex; margin-top:1.5rem;
      @include breakpoint(768 down) { justify-content:center; }
      .line { background:#BEEEE4; width:45px; height:1px; margin-top:12px;
        @include breakpoint(768 down) { display:none; }
      }
      .text { margin-left:18px;
        @include breakpoint(768 down) {margin-left:0; }
        p { font-size:14px; line-height:1.2; color:$primary-color; margin-bottom:0; width:100%; font-size:16px; font-weight:$global-weight-medium;
          @include breakpoint(640 down) { font-size:13px; font-weight:$global-weight-bold; }
        }
        small { display:inline-block; display:inline-block; font-size:12px; font-weight:$global-weight-light; }
      }
    }
  }

  .testimonial-play-button { transition:0.5s; background:rgba($white,0.9); margin-left:1.5rem; display:inline-block; border-radius:100%; height:75px; width:75px; position:absolute; top:50%; left:50%; transform:translate(-50%, -50%); border:1px solid $white;
      @include breakpoint(480 down) { margin-left:0; margin-top:1rem; height:65px; width:65px; }
      i { font-size:14px; color:$primary-color; position:absolute; top:50%; left:50%; transform:translate(-50%,-50%); }
      &:hover { border:1px solid $white; background:$white;
        i { color:$secondary-color; }
      }
  }
}

/* Case Study Block */
.case-studies-carousel { margin-left:6%; position:relative; margin-top:1.5rem;
  @include breakpoint(1200 down) { margin-left:0; }
  .case-study-block {
    &__overlay { position:absolute; top:0; left:0; right:0; bottom:0; background:rgba(17,17,17,0.4); }
    &__image { position:relative; background-repeat:no-repeat; background-size:cover; background-position:center; }
    &__text { color:$white; padding:6rem; position:relative; z-index:10;
      @include breakpoint(640 down) { padding:3rem; }
      @include breakpoint(480 down) { padding-top:10rem; padding-left:1.25rem; padding-right:1.25rem; padding-bottom:2rem; }
      h3 {
        @include breakpoint(640 down) { margin-bottom:1.5rem; }
      }
      p {
        @include breakpoint(640 down) { display:none; }
      }
      a {
          margin-top:1rem; display:inline-block;
      }
      .text-container {
          height:85px;
      }
    }
  }
  .slick-list {
    &:before {
       @include breakpoint(1200 up) { content:""; display:block; background:$white; position:absolute; width:650px; left:-500px; height:100%; z-index:10; }
    }
  }
  .slick-arrow { position:absolute; top:50%;
    @include breakpoint(1200 down) { top:inherit; bottom:-75px; left:45%; transform:translateY(-50%); }
    &.slick-prev { margin:0 -25px; }
    &.slick-next { margin:0 20px; }
  }
}

/* Call To Action */
.cta-contact {  background-repeat:no-repeat; background-size:cover; background-position:center; background-image:url('../images/bg-logo.png');
  @include breakpoint(640 down) { background-position:75%; }
  &__container { display:flex; align-items:center; color:$white; justify-content:center;
    @include breakpoint(768 down) { flex-wrap:wrap; }
    @include breakpoint(480 down) { max-width:320px; margin:0 auto; }
    h2 { line-height:1.4;
      @include breakpoint(768 down) { margin-bottom:1.5rem; width:100%; }
      @include breakpoint(640 down) { font-size:20px; }
    }
    .button { margin-left:1rem; margin-bottom:0;
      @include breakpoint(640 down) { margin-left:0; }
    }
  }
  &.secondary-color {
    .cta-contact__container { color:$primary-color; }
  }
}

/* Slider Navigation */
#vslider { width:100%; position:relative; max-width:1600px; margin:0 auto; }
#vslidernav { position:absolute; top:50%; right:0; transform:translateY(-50%); z-index:999; width:400px;
  @include breakpoint(1400 up) { right:5%; width:500px; }
}
#vsliderboxes { position : relative; overflow : hidden; display:flex; justify-content:center; }

.vslider-title { position:absolute; top:47%; left:2%; transform:translateY(-50%);
  @include breakpoint(640 down) { left:50%; transform:translateX(-50%); top:8%; }
  h3 { writing-mode: vertical-rl; z-index:9999; color:$white; transform: rotate(180deg); font-size:20px; font-weight:$global-weight-light;
    @include breakpoint(640 down) { writing-mode:inherit; transform:none; }
  }
}

.vslider-cta { display:flex; align-items:center;position:absolute; left:50%; bottom:10%; transform:translateX(-50%);
  @include breakpoint(640 down) { flex-wrap:wrap; text-align:center; justify-content:center;}
  @include breakpoint(480 down) { width:100%; bottom:5%; padding:0 0.9375rem; }
  p { color:$white; margin-bottom:0; 
      @include breakpoint(640 down) { width:100%; }
  }
  .button { margin-left:1rem; margin-bottom:0;
    @include breakpoint(640 down) { margin-left:0; margin-top:12px; }
  }
}

#vslidernav ul { list-style: none; padding: 0; }
#vslidernav ul a { padding: 0; cursor: pointer; font-size:12px; display:block; margin-bottom:1rem; color:rgba($white, 0.7); transition:0.5s;
  @include breakpoint(1400 up) { font-size:14px; }
  &.is-active {
    font-weight:$global-weight-medium; font-size:18px; color:$white;
  }
}
#vslidernav ul a:active { color: #9C9A99; }
#vslidernav ul a:active { background: transparent; color: #9C9A99; }
#vsliderboxes div { height:750px; width:100%; background-repeat:no-repeat; background-size:cover;
  @include breakpoint(640 down) { height:650px; }
  @include breakpoint(480 down) { height:625px; }
}
#vsliderboxs-inner { position:relative;
  .vsliderboxs-inner-item { position:relative;
    &__text { position:absolute; top:85%; left:10%; transform:translateY(-50%); max-width:500px;
      @include breakpoint(1400 up) { left:18%; max-width:600px; }
      @include breakpoint(1024 down) { top:75%; }
      @include breakpoint(640 down) { top:70%; left:inherit; padding:0 0.9375rem; }
      @include breakpoint(480 down) { top:66%; }
      .number {
        color:$white; font-size:20px; font-weight:$global-weight-medium; display:block; margin-bottom:1rem;
        @include breakpoint(1024 up) { display:none; }
      }
      img { width:125px; margin-bottom:1.75rem;
        @include breakpoint(640 down) { margin-bottom:1rem; }
      }
      h3 { color:$white; font-size:40px; margin-bottom:1.5rem;
          @include breakpoint(640 down) { font-size:26px; }
      }
      p { color:$white; font-size:16px; font-weight:$global-weight-light;
        @include breakpoint(640 down) { font-size:15px; }
        @include breakpoint(480 down) { font-size:14px; }
        .line { width:1px; height:100%; background:rgba($white,0.45); display:block; }
      }
    }
  }
}

.vslider-arrows { position:absolute; bottom:22%; left:50%; transform:translateX(-50%);
  @include breakpoint(640 down) { bottom:30%; }
  @include breakpoint(480 down) { bottom:26%; }
  .vslider-next,
  .vslider-prev { background:rgba($white,0.3); z-index:10; cursor:pointer; transition:0.5s; width:35px; height:35px; border:1px solid transparent; position:relative;
    i { font-size:13px; position:absolute; top:50%; left:50%; transform:translate(-50%,-50%); }
    &:hover {
      background:$primary-color; border-color:1px solid $primary-color;
      i { color:$white; }
    }
  }

  .vslider-next { margin:0 -25px;  left:65px; }
  .vslider-prev { margin:0 20px;  left:-15px; }
}

/* News */
.news-block-bg { height:670px; background-repeat:no-repeat; position:relative; background-size:cover;
  @include breakpoint(1024 down) { height:450px; margin-bottom:1.5rem; }
  &__linear-gradient { position:absolute; top:0; left:0; right:0; bottom:0; background:linear-gradient(to bottom, transparent 80%, rgba(34,34,34,1)); }
  &__text { padding:2.25rem 2.5rem; position:absolute; bottom:0;
    @include breakpoint(640 down) { padding:1.75rem; }
    h4 { color:$white; font-size:28px; margin-bottom:1rem; font-weight:$global-weight-medium;
      @include breakpoint(640 down) { font-size:20px; }
    }
    span { color:$white; font-size:13px;
      a { color:$white;  text-decoration:underline; font-weight:$global-weight-normal;
        &:hover { color:$secondary-color; }
      }
    }
  }
}

.news-block {
  @include breakpoint(480 down) { padding-left:36px; padding-right:36px; }
  @include breakpoint(360 down) { padding-left:1rem; padding-right:1rem; }
  img { width:100%; }
  h4 { font-size:17px; color:$primary-color; margin-top:1rem; font-weight:$global-weight-medium; line-height:1.3;
    @include breakpoint(640 down) { font-size:15px; }
  }
  span { color:$white; font-size:13px; color:#222222;
    a { text-decoration:underline; color:#3EB699; font-weight:$global-weight-normal;
      &:hover { color:$primary-color; }
    }
  }
}

.newsletter-block { background:url('../images/bg-image.png'); padding:2.75rem; background-color:#7EDDC3; margin-top:2.5rem; background-position:no-repeat; background-size:cover;
  @include breakpoint(640 down) { padding:1.5rem; }
  @include breakpoint(480 down) { text-align:center;}
  &__title { font-size:18px; font-weight:$global-weight-medium; margin-bottom:0.25rem; color:$primary-color;
    @include breakpoint(480 down) { font-size:16px; }
 }
  &__text { font-size:15px; font-weight:$global-weight-normal; margin-bottom:1.5rem; color:$primary-color;
      @include breakpoint(480 down) { font-size:14px; }
  }
  &__input { position:relative;
    .email-input { height:54px; margin-bottom:0; background:$white; padding-left:25px; font-size:14px; }
    .submit-button { line-height:1; position:absolute; right:0; top:41%; transform:translateY(-50%); margin:5px; height:44px; line-height:1; }
  }
}

/* Breadcrumb */
.breadcrumb {
    &__list { display:flex; margin:0; list-style-type:none; position:relative; z-index:10; flex-wrap:wrap;
      li {
        span { font-weight:$global-weight-light; font-size:12px; color:$white; }
        a { transition:0.5s; font-size:12px; font-weight:$global-weight-medium; color:$white;
          &:hover { color:$secondary-color; text-decoration:underline; }
        }
      }
      li + li {
        &:before {
         font-size:12px;  font-family: "Font Awesome 6 Pro"; font-weight: 300; content: "\f105"; margin:0 8px; color:$white;
        }
      }
    }
}

/* Page Banner */
.page-banner { position:relative;
  &__overlay { position:absolute; top:0; left:0; right:0; bottom:0; background:rgba($black,0.25); }
  &__bg-image { background-repeat:no-repeat; background-position:center; background-size: cover; height:100%;
    &.custom-padding { padding-top:10.75rem; padding-bottom:6.75rem;
        @include breakpoint(medium down) { padding-top:8.75rem; padding-bottom:6.75rem; }
        @include breakpoint(small down) { padding-top:6.75rem; padding-bottom:4.75rem; }
    }
  }
  &__small-text {
      line-height:1; margin-top:2rem; color:$white; font-weight:$global-weight-medium; font-size:16px; margin-bottom:0.5rem; position:relative; z-index:10;
      @include breakpoint(640 down) { font-size:14px; }
      @include breakpoint(480 down) { font-size:13px; }
  }

  &__text-container { z-index:99; position:relative;
    h1 { color:$white; font-weight:$global-weight-light; font-size:50px; line-height:1.3; margin-bottom:1rem;
      @include breakpoint(768 down) { font-size:38px; }
      @include breakpoint(640 down) { font-size:30px; }
      @include breakpoint(480 down) { font-size:26px; }
    }
  }

  &__cta-container { position:relative; z-index:10;
    p { color:$white; font-weight:$global-weight-light; font-size:18px; margin-bottom:1.15rem;
      @include breakpoint(640 down) { font-size:16px; }
      @include breakpoint(480 down) { font-size:13px; }
    }
  }

  &__button-container { display:flex; align-items:center;
    @include breakpoint(480 down) { flex-wrap:wrap; }
    .button { margin-bottom:0;
      @include breakpoint(480 down) { width:100%; }
    }
    .play-button { transition:0.5s; margin-left:1.5rem; display:inline-block; border-radius:100%; height:45px; width:45px; position:relative; border:1px solid $white;
      @include breakpoint(480 down) { margin-left:0; margin-top:1rem; }
      i { font-size:8px; color:$white; position:absolute; top:50%; left:50%; transform:translate(-50%,-50%); }
      &:hover { border:1px solid $white; background:$white;
        i { color:$primary-color; }
      }
    }
    span { margin-left:10px; color:$white; font-size:15px; font-weight:$global-weight-medium;
      @include breakpoint(480 down) { margin-top:15px;}
    }
  }
}

/* Small Page Banner */
.small-page-banner {
  &__bg-image { background-repeat:no-repeat; background-position:center; background-size: cover; height:100%;
    &.custom-padding { padding-top:8.25rem; padding-bottom:3.75rem;
        @include breakpoint(1024 down) { padding-top:6.75rem; padding-bottom:3.75rem; }
        @include breakpoint(medium down) { padding-top:5.25rem; padding-bottom:3.25rem; }
        @include breakpoint(small down) { padding-top:4.75rem; padding-bottom:2.25rem; }
    }
  }
  &__text-container { padding-top:3rem;
    h1 { color:$white; font-size:60px; line-height:1.4; }
    p { color:$white; font-weight:$global-weight-light; font-size:14px; margin-bottom:0;
      span { padding-left:1rem; }
     }
  }
  &__button-container { padding-top:2rem;
    @include breakpoint(768 down) { padding-top:1.25rem; }
    .button { margin-bottom:0; }
  }
}

/* Page Banner Image */
.page-banner-image {
  &__bg-image { background-repeat:no-repeat; background-position:center; background-size: cover; height:100%;
    &.custom-padding { padding-top:8.25rem; padding-bottom:8.25rem;
        @include breakpoint(1024 down) { padding-top:6.75rem; padding-bottom:6.75rem; }
        @include breakpoint(medium down) { padding-top:5.25rem; padding-bottom:5.25rem; }
        @include breakpoint(small down) { padding-top:4.75rem; padding-bottom:0; }
    }
  }

  .page-banner-image-wrapper { display:flex; padding-top:4.5rem; position:relative;
    @include breakpoint(1024 down) { flex-wrap:wrap; padding-top:2rem;  }
    @include breakpoint(640 down) { padding-top:1.25rem; }
    &__text-container { position:relative; z-index:5; max-width:600px;
      @include breakpoint(1024 down) { max-width:inherit; width:100%; margin-bottom:1.5rem; }
      @include breakpoint(480 down) { padding-left:0.9375rem; padding-right:0.9375rem; }
      .small-text { display:inline-block; font-size:20px; font-weight:$global-weight-light; margin-bottom:1.5rem; color:$white;
        @include breakpoint(640 down) { margin-bottom:0; font-size:16px;  }
      }
      h1 { color:$white; line-height:1.2; }
      p { color:$white; font-size:18px; font-weight:$global-weight-light; line-height:1.4;
        @include breakpoint(640 down) { font-size:16px; }
        @include breakpoint(450 down) { font-size:15px; }
      }
      .button { margin-top:8px;
        @include breakpoint(450 down) { width:100%; }
      }
      .detail-text { color:$white; display:inline-block; font-size:13px; font-weight:$global-weight-light; margin-bottom:1rem;
        a { color:$secondary-color; text-decoration:underline;
          &:hover { color:$white; }
        }
        .time { padding-left:1rem;
          @include breakpoint(640 down) { padding-left:0; display:block; }
        }
      }
    }
    &__image-container { z-index:1; position:absolute; right:65px; top:60%; transform:translateY(-50%); max-width:825px;
      @include breakpoint(1320 down) { right:0; max-width:680px; }
      @include breakpoint(1024 down) { position:relative; bottom:0; right:0; top:inherit; transform:none; max-width:inherit; }
    }
  }
}

/* Page Banner Image */
.page-banner-article {
  &__bg-image { background-size:100% 75%; background-repeat:no-repeat; background-position:top center; height:100%; 
    @include breakpoint(480 down) { background-size:100% 100%; }
    &.custom-padding { padding-top:8.25rem;
        @include breakpoint(1024 down) { padding-top:6.75rem; padding-bottom:6.75rem; }
        @include breakpoint(medium down) { padding-top:5.25rem; }
        @include breakpoint(small down) { padding-top:4.75rem; padding-bottom:0; }
    }
  }

  .page-banner-article-wrapper {
    &__text-container { padding-top:2.75rem;
      @include breakpoint(480 down) { padding-left:0.9375rem; padding-right:0.9375rem; }
      h1 { color:$white; line-height:1.2; }
      .detail-text { color:$white; display:inline-block; font-size:13px; font-weight:$global-weight-light; margin-bottom:1rem;
        a { color:$secondary-color; text-decoration:underline;
          &:hover { color:$white; }
        }
        .time { padding-left:1rem;
          @include breakpoint(640 down) { padding-left:0; display:block; }
        }
      }
    }
    &__image-container { position:relative; padding-top:1rem;
      img { width:100%; }
      .play-button { transition:0.5s; background:rgba($white,0.9); margin-left:1.5rem; display:inline-block; border-radius:100%; height:85px; width:85px; position:absolute; top:50%; left:50%; transform:translate(-50%, -50%); border:1px solid $white;
        @include breakpoint(480 down) { margin-left:0; margin-top:1rem; height:75px; width:75px; }
        i { font-size:15px; color:$primary-color; position:absolute; top:50%; left:50%; transform:translate(-50%,-50%); }
        &:hover { border:1px solid $white; background:$white;
          i { color:$secondary-color; }
        }
      }
    }
  }
}

/* Page Banner Contact Us */
.page-banner-contact-us {
  &__bg-image { background-repeat:no-repeat; background-position:left; height:100%;
    &.custom-padding { padding-top:8.25rem;
        @include breakpoint(1200 down) { padding-top:6.75rem; }
        @include breakpoint(1024 down) { padding-top:6.75rem; padding-bottom:0; }
        @include breakpoint(medium down) { padding-top:5.25rem; padding-bottom:0; }
        @include breakpoint(small down) { padding-top:4.75rem; padding-bottom:0; }
    }
  }

  &__text-container { padding-top:2.75rem; padding-bottom:4.5rem;
    @include breakpoint(640 down) { padding-bottom:2rem; }
    h1 { color:$primary-color; line-height:1.2; }
    .detail-text { color:$white; display:inline-block; font-size:13px; font-weight:$global-weight-light; margin-bottom:1rem;
      a { color:$secondary-color; text-decoration:underline;
        &:hover { color:$white; }
      }
    }
    p { margin-bottom:0; font-size:20px; font-weight:$global-weight-light; line-height:38px;
       @include breakpoint(640 down) { font-size:15px; line-height:25px; }
    }
  }

  .breadcrumb__list {
    li {
      span { color:#959595; }
      a { color:#959595;
        &:hover { color:$primary-color; text-decoration:underline; }
      }
    }
    li + li {
      &:before {
       color:#959595;
      }
    }
  }
}

/* CTA Section */
.cta-section {
  &__container { display:flex; align-items:center; color:#333333; justify-content:center;
    @include breakpoint(768 down) { flex-wrap:wrap; }
    @include breakpoint(480 down) { max-width:320px; margin:0 auto; }
    h4 { line-height:1.4; margin-bottom:0; font-weight:$global-weight-light;
      @include breakpoint(768 down) { margin-bottom:1.5rem; width:100%; }
      @include breakpoint(640 down) { font-size:20px; }
    }
    .button { margin-left:1rem; margin-bottom:0;
      @include breakpoint(640 down) { margin-left:0; }
    }
  }
}


/* Card Image Block */
.card-image-block { display:flex; flex-wrap:wrap;
  .card-image-block-item { padding:4rem; color:$white; text-align:center; width:33.33%;
    @include breakpoint(1200 up) { padding:5rem; }
    @include breakpoint(1024 down) { width:50%; }
    @include breakpoint(768 down) { padding:3rem 1.5rem; }
    @include breakpoint(560 down) { width:100%; padding:3rem 1.5rem; }
    i { font-size:42px; color:$secondary-color; }
    .number { line-height:1; margin-top:1rem; margin-bottom:1rem; font-size:50px; font-weight:$global-weight-medium;
      @include breakpoint(1200 down) { font-size:40px; }
      @include breakpoint(800 down) { font-size:36px; }
      @include breakpoint(480 down) { margin-bottom:0.25rem; }
      .symbol { font-size:1rem; }
    }
    .text { line-height:20px; margin-bottom:0; max-width:200px; margin:0 auto; font-size:16px; font-weight:$global-weight-light;
      @include breakpoint(640 down) { font-size:14px; }
    }
    &.bg--secondary {
      i { color:$white; }
    }
  }
}

/* Card Block Background */
.card-block-bg { display:inline-block; background-repeat:no-repeat; background-size:cover; width:100%; position:relative; height:240px;
  @include breakpoint(1280 up) { height:280px; }
  @include breakpoint(480 down) { height:225px; }
  &__overlay { position:absolute; top:0; left:0; right:0; bottom:0; background:rgba(34,34,34,0.25); transition:0.5s; }
  &__text-container { padding:1.35rem 1.15rem;
    h4 { align-items:center; color:$white; font-weight:$global-weight-medium; position:relative; z-index:10; display:flex;
      i { margin-left:auto; order:2; opacity:0; transition:0.5s; }
    }
    p { opacity:0; color:$white; font-size:15px; font-weight:$global-weight-light; transition:0.5s; position:absolute; bottom:0; padding-right:1.15rem;
      @include breakpoint(640 down) { font-size:14px; }
    }
  }
  &:hover {
    .card-block-bg__overlay { background:rgba($secondary-color,0.8); }
    i { opacity:1; }
    p { opacity:1; }
  }
}

/* Category Filter */
.filter-category-items { display:flex; align-items:center; justify-content:center;
  p { font-size:18px; font-weight:$global-weight-light; margin-right:1rem; margin-bottom:0; min-width:75px;
    @include breakpoint(640 down) { font-size:16px; min-width:65px; margin-right:0.5rem; }
  }
  ul { display:flex; margin:0; list-style-type:none; justify-content:space-between;justify-content:center;
    @include breakpoint(1024 down) { width:100%; overflow-y:scroll; white-space:nowrap; justify-content:flex-start; }
    li { padding:0 4px;
      &.is-active {
        a { background:$primary-color; color:$white;}
      }
      a { display:inline-block; transition:0.5s; font-weight:$global-weight-medium; font-size:15px; padding:12px 18px; background:#F4F4F4; color:#222222; border-radius:35px; line-height:1;
        @include breakpoint(640 down) { font-size:14px; }
        &:hover { background:$primary-color; color:$white; }
      }
    }
  }
}

/* Social Media */
.social-icon {
  &__list { list-style-type: none; margin: 0; display:flex; align-items:center; justify-content:center;
    li + li { display:inline-block; position:relative; width:35px; height:35px; cursor:pointer; border-radius:100%; margin-right:0.7rem; background:#f4f4f4; transition:0.5s;
      a { color:$primary-color; transition:0.5s; position:absolute; top:52%; left:50%; transform:translate(-50%,-50%);
        i { font-size:15px; }
      }
      &:hover { background:$primary-color;
        a { color:$white; }
      }
    }
    span { display:inline-block; font-size:16px; font-weight:$global-weight-light; margin-right:0.75rem; }
  }
}

.form-container {
    label { font-weight:$global-weight-bold; letter-spacing:-0.2px; text-align:left; }
    .file-upload {
        padding:0.75rem 1.5rem; border:1px solid #E6E6E6;
        @include breakpoint(900 down) { padding:1rem; }
        @include breakpoint(640 down) { font-size:13px; }
    }
    .checkbox-container { display:flex; align-items:center; padding:0.75rem 0;
        input { margin-bottom:0; }
        span { font-size:12px; color:#333333; margin-left:8px; font-weight:$global-weight-normal; }
    }
    a { text-decoration:underline; font-weight:$global-weight-medium;
      &:hover { color:$secondary-color; }
    }
}

.pb-contact {
  button {
    @include breakpoint(480 down) { width:100%; }
  }
}

/* contact Us */
.contact-us-block { height:850px; position:relative;
  @include breakpoint(1180 down) { height:700px; }
  @include breakpoint(640 down) { height:inherit; }
  &__items { padding-top:4rem;
    @include breakpoint(640 down) { padding-top:1.5rem; }
    .contact-us-block-details { display:flex; margin-bottom:1.75rem;
      p { color:$white; font-size:22px; font-weight:$global-weight-light; line-height:35px;
        @include breakpoint(640 down) { font-size:16px; }
      }
      .icon { width:6%; margin-top:4px;
        @include breakpoint(800 down) { width:8%; }
        i { color:$secondary-color; font-size:20px;
          @include breakpoint(640 down) { font-size:18px; }
        }
      }
      .text { width:94%; color:$white; font-weight:$global-weight-light; font-size:22px;
        @include breakpoint(640 down) { font-size:16px; }
        a { color:$white; display:block; font-size:22px; font-weight:$global-weight-light;
          @include breakpoint(640 down) { font-size:16px; }
        }
        .link { text-decoration:underline; font-size:18px; transition:0.5s;
          @include breakpoint(640 down) { font-size:16px; }
          &:hover { color:$primary-color; }
        }
      }
    }
  }
  &__image { position:absolute; z-index:-1; background-repeat:no-repeat; background-size:cover; width:100%; left:0; width:48%; height:850px;
    @include breakpoint(800 up) { width:45%; }
    @include breakpoint(640 down) { width:100%; height:280px; bottom:0; }
  }
}

/* Team Block */
.team-block {
  &__text-container { margin-top:1.5rem;
    .link {
      @include breakpoint(640 down) { font-size:15px; }
    }
  }
  &__image-container {
    img { width:100%; }
  }
  &__position { display:flex; align-items:center; margin-bottom:1rem;
    p { margin-bottom:0; font-size:16px; font-weight:$global-weight-medium; color:#959595;
      @include breakpoint(640 down) { font-size:14px; }
    }
    ul { margin:0; list-style-type:none; display:flex; margin-left:0.85rem;
      li { padding:0 5px;
        a {
          i { font-size:18px; transition:0.5s;
            @include breakpoint(640 down) { font-size:16px; }
          }
          &:hover {
            i { color:$secondary-color; }
          }
        }
      }
    }
  }
}

/* Page Builder Download */
.careers-block {
  .careers-block-item { display:flex; align-items:center; padding:1.5rem; border:1px solid #F4F4F4; background:$white; margin-bottom:15px;
    @include breakpoint(640 down) { flex-wrap:wrap; }
    &__text { width:82%;
      @include breakpoint(640 down) { width:100%; }
      p { margin-bottom:0; font-size:17px; line-height:28px; font-weight:$global-weight-normal;
          @include breakpoint(640 down) { font-size:15px; line-height:1.5; }
          @include breakpoint(480 down) { font-size:14px; }
      }
    }
    &__top { display:flex; align-items:center;
      @include breakpoint(480 down) { flex-wrap:wrap; }
      h4 { font-size:18px; font-weight:$global-weight-medium; margin-bottom:0; line-height:32px;
        @include breakpoint(640 down) { font-size:16px; }
        @include breakpoint(480 down) { width:100%; font-size:15px; font-weight:$global-weight-normal;  line-height:26px; }
      }
      span {  display:inline-block; font-size:14px; font-weight:$global-weight-light; color:#959595; padding-left:1.5rem; line-height:32px;
        @include breakpoint(640 down) { font-size:13px; }
        @include breakpoint(480 down) { line-height:32px;
          &:first-of-type { padding-left:0; }
        }
      }
    }
    &__link { width:18%; text-align:right;
      @include breakpoint(640 down) { width:100%; text-align:left; margin-top:12px; }
      a { font-size:16px; font-weight:$global-weight-medium; text-decoration:underline;
        @include breakpoint(640 down) { font-size:15px; }
        &:hover { color:$secondary-color; }
      }
    }
  }
}

.job-detail-table { margin-bottom:2.5rem;
  .job-detail-row { display:flex; padding:12px 16px; border:1px solid #F7F7F7;
    @include breakpoint(640 down) { flex-wrap:wrap; }
    &__title { width:23.5%; max-width:190px;
      @include breakpoint(640 down) { width:100%; }
      p { margin-bottom:0; font-size:18px; font-weight:$global-weight-medium; line-height:28px;
        @include breakpoint(640 down) { font-size:15px; line-height:1.4; }
      }
    }
    &__text { width:76.5%;
      @include breakpoint(640 down) { width:100%; }
      p { margin-bottom:0; font-size:18px; font-weight:$global-weight-normal; line-height:28px;
        @include breakpoint(640 down) { font-size:15px; line-height:1.4; }
      }
    }
    &:nth-of-type(even) {
      background:#F7F7F7;
    }
  }
}

.mapouter {
    position: relative;
    text-align: right;
    height: 380px;
    width: 100%;
}

.gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 380px;
    width: 100%;
}
