
/* - - - - - - - - - - - - PB DOWNLOADS - - - - - - - - - - - - */

.pb--downloads {

	.pb--downloads--item {

	    padding-top: 20px;
	    padding-bottom: 20px;

	    .pb--downloads--img {
    	    box-shadow: 0 1px 4px rgba(0,0,0,.2);
	    	height: 210px;
	    	background-size: cover;
		}

	}

}

@media only screen and (max-width: 1023px) {

	.pb--downloads {

		.pb--downloads--outer {
    		padding-top: 10px;
		}

		.pb--downloads--item {
		    padding-top: 8px;
		    padding-bottom: 8px;
		}

	}

}

@media only screen and (max-width: 639px) {

	.pb--downloads {

		.pb--downloads--item {
	    	padding-bottom: 24px;
	    }

	}

}
