/* Web Fonts */
@font-face {
    font-family: 'Gotham';
    src: url('../webfonts/gotham-fonts/Gotham-Black.woff2') format('woff2'),
        url('../webfonts/gotham-fonts/Gotham-Black.woff') format('woff'),
        url('../webfonts/gotham-fonts/Gotham-Black.ttf') format('truetype'),
        url('../webfonts/gotham-fonts/Gotham-Black.otf') format('otf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../webfonts/gotham-fonts/Gotham-Bold.woff2') format('woff2'),
        url('../webfonts/gotham-fonts/Gotham-Bold.woff') format('woff'),
        url('../webfonts/gotham-fonts/Gotham-Bold.ttf') format('truetype'),
        url('../webfonts/gotham-fonts/Gotham-Bold.otf') format('otf');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../webfonts/gotham-fonts/Gotham-BookItalic.woff2') format('woff2'),
        url('../webfonts/gotham-fonts/Gotham-BookItalic.woff') format('woff'),
        url('../webfonts/gotham-fonts/Gotham-BookItalic.ttf') format('truetype'),
        url('../webfonts/gotham-fonts/Gotham-BookItalic.otf') format('otf');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../webfonts/gotham-fonts/Gotham-Medium.woff2') format('woff2'),
        url('../webfonts/gotham-fonts/Gotham-Medium.woff') format('woff'),
        url('../webfonts/gotham-fonts/Gotham-Medium.ttf') format('truetype'),
        url('../webfonts/gotham-fonts/Gotham-Black.otf') format('otf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../webfonts/gotham-fonts/gotham-fonts/Gotham-ThinItalic.woff2') format('woff2'),
        url('../webfonts/gotham-fonts/Gotham-ThinItalic.woff') format('woff'),
        url('../webfonts/gotham-fonts/Gotham-ThinItalic.ttf') format('truetype'),
        url('../webfonts/gotham-fonts/Gotham-Black.otf') format('otf');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../webfonts/gotham-fonts/Gotham-Book.woff2') format('woff2'),
        url('../webfonts/gotham-fonts/Gotham-Book.woff') format('woff'),
        url('../webfonts/gotham-fonts/Gotham-Book.ttf') format('truetype'),
        url('../webfonts/gotham-fonts/Gotham-Black.otf') format('otf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('./webfonts/gotham-fonts/Gotham-MediumItalic.woff2') format('woff2'),
        url('./webfonts/gotham-fonts/Gotham-MediumItalic.woff') format('woff'),
        url('./webfonts/gotham-fonts/Gotham-MediumItalic.ttf') format('truetype'),
        url('../webfonts/gotham-fonts/Gotham-Black.otf') format('otf');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../webfonts/gotham-fonts/Gotham-LightItalic.woff2') format('woff2'),
        url('../webfonts/gotham-fonts/Gotham-LightItalic.woff') format('woff'),
        url('../webfonts/gotham-fonts/Gotham-LightItalic.ttf') format('truetype'),
        url('../webfonts/gotham-fonts/Gotham-Black.otf') format('otf');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../webfonts/gotham-fonts/Gotham-XLight.woff2') format('woff2'),
        url('../webfonts/gotham-fonts/Gotham-XLight.woff') format('woff'),
        url('../webfonts/gotham-fonts/Gotham-XLight.ttf') format('truetype'),
        url('../webfonts/gotham-fonts/Gotham-Black.otf') format('otf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../webfonts/gotham-fonts/Gotham-UltraItalic.woff2') format('woff2'),
        url('../webfonts/gotham-fonts/Gotham-UltraItalic.woff') format('woff'),
        url('../webfonts/gotham-fonts/Gotham-UltraItalic.ttf') format('truetype'),
        url('../webfonts/gotham-fonts/Gotham-Black.otf') format('otf');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../webfonts/gotham-fonts/Gotham-Medium.woff2') format('woff2'),
        url('../webfonts/gotham-fonts/Gotham-Medium.woff') format('woff'),
        url('../webfonts/gotham-fonts/Gotham-Medium.ttf') format('truetype'),
        url('../webfonts/gotham-fonts/Gotham-Black.otf') format('otf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../gotham-fonts/Gotham-BoldItalic.woff2') format('woff2'),
        url('../gotham-fonts/Gotham-BoldItalic.woff') format('woff'),
        url('../webfonts/gotham-fonts/Gotham-BoldItalic.ttf') format('truetype'),
        url('../webfonts/gotham-fonts/Gotham-Black.otf') format('otf');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../webfonts/gotham-fonts/Gotham-Bold.woff2') format('woff2'),
        url('../webfonts/gotham-fonts/Gotham-Bold.woff') format('woff'), 
        url('../webfonts/gotham-fonts/Gotham-Bold.ttf') format('truetype'),
        url('../webfonts/gotham-fonts/Gotham-Black.otf') format('otf');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('../webfonts/gotham-fonts/Gotham-XLightItalic.woff2') format('woff2'),
      url('../webfonts/gotham-fonts/Gotham-XLightItalic.woff') format('woff'),
      url('../webfonts/gotham-fonts/Gotham-XLightItalic.ttf') format('truetype'),
      url('../webfonts/gotham-fonts/Gotham-Black.otf') format('otf');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('../webfonts/gotham-fonts/Gotham-Light.woff2') format('woff2'),
      url('../webfonts/gotham-fonts/Gotham-Light.woff') format('woff'),
      url('../webfonts/gotham-fonts/Gotham-Light.ttf') format('truetype'),
      url('../webfonts/gotham-fonts/Gotham-Black.otf') format('otf');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('../webfonts/gotham-fonts/Gotham-Thin.woff2') format('woff2'),
      url('../webfonts/gotham-fonts/Gotham-Thin.woff') format('woff'),
      url('../webfonts/gotham-fonts/Gotham-Thin.ttf') format('truetype'),
      url('../webfonts/gotham-fonts/Gotham-Black.otf') format('otf');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../webfonts/gotham-fonts/Gotham-BookItalic.woff2') format('woff2'),
        url('../webfonts/gotham-fonts/Gotham-BookItalic.woff') format('woff'),
        url('../webfonts/gotham-fonts/Gotham-BookItalic.ttf') format('truetype'),
        url('../webfonts/gotham-fonts/Gotham-Black.otf') format('otf');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}


@mixin transitions {
	transition: 500ms;
	-webkit-transition: 500ms;
	-moz-transition: 500ms;
	-o-transition: 500ms;
	-ms-transition: 500ms;
}

select:focus {
	box-shadow: none;
}
:focus {
  outline: none;
}

.lead-paragraph { font-size:22px; font-weight:$global-weight-light; line-height:32px;
  @include breakpoint(640 down) { font-size:18px; line-height:1.3; }
}


.gradient--bottom:before {
    content: "";
    display: block;
    background-size: cover !important;
    position: absolute;
    bottom: 0;
    width: 100%;
    top: 0;
    background: rgba(0,0,0,.3);
    background: linear-gradient(to bottom, transparent 50%, rgba(0,0,0,0.8) 100%);
}


.float__right {
	float: right;
}
.text--underline {
	text-decoration: underline;
}

.maxw--700--center {
	max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}
.maxw--800--center {
	max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}
.width--100 {
	width: 100% !important;
}
.table-scroll {
    overflow-x: scroll;
}

blockquote { padding-top:1.25rem; padding-bottom:1.25rem; color:#333333; margin-left:0; font-size:20px; border-left:none; font-weight:$global-weight-medium; text-align:center;
  @include breakpoint(640 down) { font-size:18px; }
  @include breakpoint(480 down) { font-size:16px; }
  span { line-height:1; display:block; font-size:40px; text-align:center; color:$primary-color; }
}

.list-tick-icon { margin:0; list-style-type:none;
  li { padding-bottom:0.75rem; font-size:16px; color:#333333; font-weight:$global-weight-normal; display:flex; align-items:center;
    @include breakpoint(640 down) { font-size:15px; }
    @include breakpoint(480 down) { font-size:14px; }
    &:before {
      content: "\f00c"; font-family:"Font Awesome 6 Pro"; display:inline-block; font-weight:900; width:18px; height:18px; border-radius:50%; background:#F4F4F4; color:$primary-color; font-size:10px; padding:4px; line-height:1; margin-right:0.9rem;
    }
  }
}

.list-tick-primary-icon { columns:2; margin:0;
  @include breakpoint(640 down) { columns:1; }
  li { padding-bottom:0.75rem; font-size:18px; color:#333333; font-weight:$global-weight-medium; display:flex; line-height:28px;
    @include breakpoint(640 down) { font-size:16px; }
    @include breakpoint(480 down) { font-size:15px; line-height:24px; }
    &:before {
      content: "\f00c"; margin-top:4px; font-family:"Font Awesome 6 Pro"; display:inline-block; font-weight:900; width:22px; height:22px; border-radius:50%; background:$primary-color; color:$white; font-size:13px; padding:5px; line-height:1; margin-right:0.9rem;
    }
  }
}

@media only screen and (min-width: 769px) {

	.float__right--large {
		float: right;
	}

}
@media only screen and (min-width: 641px) and (max-width: 768px) {

	.float__left--medium {
		float: left;
	}

}
@media only screen and (max-width: 639px) {

	.float__none--small {
		float: none !important;
	}
	.width-100-small {
		width: 100%;
		max-width: 100%;
		min-width: 100%;
	}

}

.text--center {
	text-align: center;
}

.img--center {
	margin-left: auto;
	margin-right: auto;
	display: block;
	float: none;
}

.menu__flex--center {
    justify-content: center;
}
.menu__flex--around {
    justify-content: space-around;
}
.menu__flex--between {
    justify-content: space-between;
}
.menu__flex--even {
    justify-content: space-evenly;
}

@media only screen and (max-width: 769px) {

	.img--center--tablet- {
		margin-left: auto;
		margin-right: auto;
		display: block;
		float: none;
	}

	.menu__flex--center--tablet- {
	    justify-content: center;
	}

}

@media only screen and (max-width: 639px) {

	.img--center--mob {
		margin-left: auto;
		margin-right: auto;
		display: block;
		float: none;
	}

	.menu__flex--center--mob {
	    justify-content: center;
	}

}
