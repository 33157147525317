.footer {
  &__cta { display:flex; align-items:center; justify-content:center; padding:2rem 0;
    @include breakpoint(640 down) { text-align:center; }
    p { font-size:16px;
      @include breakpoint(640 down) { font-size:14px; }
      .button { margin-bottom:0;
        @include breakpoint(480 down) { margin-top:0.75rem; }
      }
    }
  }

  &__copyright { display:flex; justify-content:space-between;
    @include breakpoint(480 down) { flex-wrap:wrap; justify-content:center; }
    p { font-size:13px; color:#959595; margin-bottom:0;
      @include breakpoint(480 down) { fpnt-size:12px; width:100%; order:3; text-align:center; margin-top:10px; }
    }
    .footer-social__list { display:flex; align-items:center; margin:0; list-style-type:none;
      @include breakpoint(480 down) { justify-content:center; width:100%; margin-bottom:8px; }
      li { padding:0 6px;
        a { color:$primary-color; font-size:13px; transition:0.5s;
          &:hover { color:$secondary-color; }
        }
      }
    }
    .footer-link__list { display:flex; margin:0; list-style-type:none;
      li {
        &:first-child { padding-right:8px; color:#959595; }
        a { color:#959595; font-size:13px; transition:0.5s;
          @include breakpoint(480 down) { font-size:12px; }
          &:hover { color:$secondary-color; }
        }
      }
    }
  }

  .footer-quality-logo { margin-top:2.5rem;
    @include breakpoint(640 down) { margin-top:0; margin-bottom:2rem; text-align:center; }
  }
  .footer-brand-logo {
    @include breakpoint(640 down) { text-align:center; }
  }
  .footer-navigation { display:flex; 
    @include breakpoint(640 down) { flex-wrap:wrap; }
    .footer-navigation-item { width:50%; display:flex; flex-wrap:wrap;
      @include breakpoint(640 down) { width:100%; }
      &:first-of-type {
        @include breakpoint(640 down) { margin-bottom:1rem; }
      }
      h6 { color:#222222; margin-bottom:1.5rem; width:100%;
        @include breakpoint(480 down) { font-size:14px; margin-bottom:1rem; }
      }
      ul { margin:0; list-style-type:none; width:50%;
        li { margin-bottom:12px;
          a { transition:0.5s; color:$primary-color; font-size:15px; font-weight:$global-weight-medium;
            &:hover { color:$secondary-color; }
            @include breakpoint(640 down) { font-size:14px; }
            @include breakpoint(480 down) { font-size:13px; }
          }
        }
      }
    }
  }
}
