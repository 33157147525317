// Thin						100
// Light					300
// Regular					400
// Medium					500
// Semibold					600
// Bold						700
// Black					800
// Heavy					900

.weight--light 					{ font-weight: 300; }
.weight--regular 				{ font-weight: 400; }
.weight--medium 				{ font-weight: 500!important; }
.weight--semibold 				{ font-weight: 600; }
.weight--bold 					{ font-weight: 700; }
// .weight--heavy 				{ font-weight: 800; }
.weight--black 					{ font-weight: 900; }
