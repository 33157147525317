
/* - - - - - - - - - - - - PB IMAGE TEXT HALF BG BACKGROUND - - - - - - - - - - - - */

.pb--half-bg {

	background-color: $secondary-color;
    background-size: 50%;
    background-repeat: no-repeat;
    position: relative;

    &.pb--half-bg--text-img {
		background-position: center right;
    }
	
    &.pb--half-bg--img-text {
    	background-position: center left;
    }

	.pb--half--outer {
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    height: 100%;
   }

	.pb--half--inner--img {
    	border-radius: $global-radius;
    	// overflow: hidden;
	}

}

@media only screen and (max-width: 1023px) {

	.pb--half-bg {

		background-size: cover;

		&:before {
		    content: "";
		    display: block;
		    background-size: cover !important;
		    background: rgba(0,0,0,0.5);
		    position: absolute;
		    bottom: 0;
		    width: 100%;
		    top: 0;
		    z-index: 1;
		}

		.pb--half--inner--content {
		    color: #fff;
		    z-index: 2;
		}

		&.pb--half-bg--text-img, &.pb--half-bg--img-text {
			background-position: center;
	    }

	}

}