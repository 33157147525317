.pb--intro {

	padding-top: 6rem;
    padding-bottom: 6rem;

	p {
		font-size: 1.25rem;
		margin: 0 0 2rem 0;
	}

	.cell {

		*:last-child {
			margin-bottom: 0;
		}

	}

	ul {

		li {

			a {

				font-weight: 500;
				font-size: 18px;
				color: $black;

				&:before {
    				content: "\f178";
    				font-family: 'FontAwesome';
				    margin-right: 8px;
			        font-size: 14px;
			        color: $primary-color;
				}

			}

		}

	}

}