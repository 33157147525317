.page-builder.highlight {

  .card__highlight {
    background: $white;
    padding: 4rem 2rem;
    border: $global-border;
    // transition: all .2s ease-in-out;
    &:hover {
      // transform: scale(1.025);
      -webkit-box-shadow: 0px 0px 16px 8px rgba(238,238,238,0.3333);
      -moz-box-shadow: 0px 0px 16px 8px rgba(238,238,238,0.3333);
      box-shadow: 0px 0px 16px 8px rgba(238,238,238,0.3333);
    }
    img {
      margin: 0 0 4rem 0;
    }
    h4 {
      color: $primary-color;
    }
    *:last-child {
      margin: 0;
    }
  }  
  
}