.form-input {

  background: $white;
  width: 100%;
  position: relative;
  border: 1px solid $light-gray;

  label {
    line-height: 1;
    font-size: .75rem;
    padding: .625rem 1rem .125rem 1rem;
    color: $dark-gray;
  }

	input[type='text'], 
	input[type='email'], 
	input[type='tel'], 
	input[type='password'],
  select,
  textarea {
  	border: none;
  	margin: 0;
    font-size: 1.125rem;
    padding: 0 1rem;
    line-height: 1;
  }
  textarea {
    padding: .375rem 1rem;
    height: 90px;
    line-height: 1.4;
  }

  &.form-input--select::after {
    position: absolute;
    right: 1rem;
    top: 1.75rem;
    content: '';
    border-top: 6px solid $primary-color;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;      
  }

  select {
    height: 2.25rem;
    margin: 0;
    background: none;
    // font-weight: 600;
    // padding: 0 .5rem;
  }

}