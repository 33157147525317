.pb--block--icons {

	.menu {

	    display: flex;
	    justify-content: space-evenly;
	    align-items: center;
	    padding-top: 2rem;

	    .pod {

		    padding: 1.4rem 5rem 1rem;
		    // border: 1px solid #eee;
		    border-radius: $global-radius;
		    @include transitions();

		    h5, span {
		    	color: $black;
		    }

		    i {
		    	font-size: 3rem;
			    color: $secondary-color;
			    padding-bottom: 1rem;
			    @include transitions();
		    }

		    &:hover {

		    	// background-color: #eee;

		    	i {
		    		transform: translateY(-5px);
		    	}

		    }

		}

	}

	

}