.off-canvas {

  padding: 1rem;

  .close-offcanvas {
    position: absolute;
    top: 1rem;
    right: 1rem;
    border-radius: 90px;
    padding: 1rem;
    background: $secondary-color;
  }

	// Bottom Navigation
  #primary {

  	background: transparent;

    a {
      color: $primary-color;
      font-size: 1.25rem;
      font-weight: 600;
      padding: 1rem;
      border-radius: $global-radius;
			i {
				float: right;
			}
			&:after {
				display: none;
			}
    }

		li[aria-expanded="true"]>a {
		  background-color: $light-gray;
		}

    ul.nested {

			background: transparent;
			margin: 0;
			padding: .5rem 0;
      border-bottom: 0;

	    li {
	    	width: 49%;
	    	margin: 0 2% 2.5% 0;
	    	float: left;
	    	display: inline;
	    	text-align: center;
	    	border: 1px solid $light-gray;
	    	border-radius: $global-radius;
	    	&:nth-child(even){
	    		margin-right: 0;
	    	}
		    a {	
		    	color: $black;
		    	border: none;
					font-size: .875rem;
					font-weight: 600;
					padding: 1rem .25rem;
					img {
						display: block;
						margin: 0 auto .5rem auto;
					}
		    }
		  }

	  }

  } //Primary




  #secondary {

		border-top: 1px solid $light-gray;
		margin-top: 2rem;
		padding-top: 2rem;

    a {
      color: $black;
      padding: .75rem 1rem;
			i {
				float: right;
			}
			&:after {
				display: none;
			}
    }

	}


}