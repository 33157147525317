
/* - - - - - - - - - - - - PB HORIZONTAL LINKS - - - - - - - - - - - - */

.pb--links-horizontal {

	// border-bottom: 1px solid $light-gray;

	ul {

		justify-content: space-around;

		 li {

		    flex: 1;
		    text-align: center;

		    a {

		    	@include transitions();
		    	// padding-top: 8px;
		    	// padding-bottom: 8px;

			//     &:hover, &.active {
	  //   			box-shadow: inset 0 -2px 0 0;
			// 	}

			}

		}

	}

}

@media only screen and (max-width: 639px) {

	.pb--links-horizontal {

		.pb--links-horizontal--inner {

			overflow-x: scroll;
			overflow-y: hidden;
			white-space: nowrap;

			ul {

			    display: block;

				li {
				    display: inline-block;
				}

			}

		}

	}

}

