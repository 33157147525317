.page-builder.testimonial {
  p {
    font-size: 1.375rem;
  }
  .name {
    display: block;
    color: $primary-color;
    font-size: 1.25rem;
    font-weight: 400;
    margin-bottom: .5rem;
  }
  .company {
    display: block;
    color: $dark-gray;
    font-size: .875rem;
    margin-bottom: 4rem;
  }
}

@media screen and (max-width: 64em) {
  .page-builder.testimonial {
    p {
      font-size: 1.125rem;
    }
    .name {
      font-size: 1rem;
    }
    .company {
      margin-bottom: 2rem;
    }
  }
}