.page-builder.form {

  fieldset {
    legend {
      font-size: 1.125rem;
      font-weight: 500;
      color: $black;
    }
  }
  .callout {
    border: none;
    background: $light-gray;
    input {
      margin: 0;
    }
  }
  .button {
    font-size: 1.375rem;
    padding: 1.375rem 0;
    background: #09d5a4;
  }
}