
/* - - - - - - - - - - - - SECTION CARD 3 COLUMN - - - - - - - - - - - - */

.card--std {

  display: block;
  @include transitions();

  .card--img {
    // height: 220px;
    background-size: cover !important;
    background-position: center !important;
    display: block;
  }

  .card--content {

    // background-color: $white;
    // padding: 30px;
    // color: $black;

    .card--title {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      // font-weight: 700;
    }

    span {
    	display: block;
  	}

  }

  // &:hover {
  //   box-shadow: 0 10px 40px #DDDCDA;
  //   transform: translateY(-4px);
  // }

}

.card--car {

  .card--content {

    padding: 25px 30px 30px;

    .card--car--price {
      font-weight: 700;
    }

  }

  // span {
  //   display: block;
  // }

  h3 , .card--car--info {
    color: $primary-color;
  }

  .button {
    margin-bottom: 0;
  }

}

.card--car--both, .card--car--used {

  .card--img {

    position: relative;

    &:before {
      content: "";
      display: block;
      background-size: cover !important;
      background: rgba(14,16,18,.15);
      position: absolute;
      bottom: 0;
      width: 100%;
      top: 0;
    }

    span {
      color: $white;
      position: absolute;
      top: 15px;
      left: 40px;
      font-weight: 600;

      i {
        font-family: 'Font Awesome 5 Pro' !important;
        font-style: normal;
        position: absolute;
        left: -21px;
        top: 5px;
        font-weight: 400;
      }

    }

  }

  .card--info-cols {

    p {
      line-height: 1.6;
    }

  }

}

.card--car--new {

  border-top: 1px solid $primary-color;

  hr {
    width: calc(100% - 40px);
    border-bottom: 1px solid $light-gray;
    margin-top: 0;
    margin-bottom: 0;
  }

}

.card--price {

  position: relative;

  .card--top-price {
    background-color: $primary-color;
    text-align: center;
    color: $white;
    padding: 25px 16px 24px;

    p {
      margin-bottom: 0;
      line-height: 1.2;
      text-transform: uppercase;  
    }
    
  }

  .card--content {

    ul {

      margin-left: 0;
      line-height: 2.5;
      // margin-bottom: 28px;
      margin-bottom: 75px;

      li {

        list-style-type: none;
        position: relative;
        padding-left: 1.5rem;

        &:before {
          content: "\f00c";
          font-family: 'Font Awesome 5 Pro';
          position: absolute;
          left: 0;
          top: 5px;
          font-size: 12px;
          color: $primary-color;
          font-weight: 700;
        }

      }

    }

    .button {
      display: block;
      width: calc(100% - 60px);
      position: absolute;
      bottom: 30px;
    }

  }

}

.card--compare {

  box-shadow: none;

  &:hover {
    box-shadow: none;
    transform: none;
  }

  .card--img {
    height: 170px;
  }

  .card--content {
    padding: 15px 0;
  }

  .button {
    width: 100%; 
  }

}

.card--warranty {

  display: block;
  text-transform: uppercase;
  text-align: center;
  background-color: $black;
  color: $white;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 1rem;

  &:hover {
    color: $white;
  }

}

.card--viewers {
  text-align: center;
  background-color: $medium-gray;
  padding: 12px 16px 14px;
}

@media only screen and (min-width: 640px) {

  .block--book-service-tables {

    .card--car {

      .card--content {
        min-height: 410px;
      }

    }

  }

}














/* SECTION RELATED NEWS */

.pb--block--cards {

	.pb--card {

	    display: block;
        margin-bottom: 30px;

	    .pb--card--img {
			height: 220px;
			background-size: cover !important;
			background-position: center !important;
	    }

	    .pb--card--content {

			padding: 20px;
			border: 1px solid #ddd;
			border-top: none;

		h3 {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
	      }

		p {
	      	margin-bottom: 0;
		}

	    }

	    &:hover {
			box-shadow: 0 10px 40px #DDDCDA;
			transform: translateY(-4px);
	    }

  	}

}








.pb--cards {

	.card__cat--sub--info {

		.head--small {
			overflow: hidden !important;
			text-overflow: ellipsis !important;
			white-space: nowrap !important;
		}

		p {
			margin-top: 8px;
		}

	}

	.text--overflow {
	    overflow: hidden;
	    max-height: 100px;
	}

}

@media only screen and (min-width: 769px) {

	.pb--cards {

		.cell:nth-of-type(n+4) {
			margin-top: 1.5rem;
		}

	}

}

