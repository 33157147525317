.pb--block--accordion {

	clear: left;

	li {

		&:before {
			content: "" !important;
		}
	
		.accordion-title {
		    // font-size: 20px;
		    // font-weight: 700;
		}

		// &.is-active {

		// 	&>a {
		// 		background-color: #E4E4E4 !important;
		// 	}

		// }

	}

}