.video-container {
  position:relative;
  padding-bottom: 56.4%;
  padding-top: 0;
  height: 0;
  overflow: hidden;
}

.video-container iframe, .video-container object, .video-container embed {
  position:absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20000;
}